import React, { useState, useEffect } from 'react';

import { Topic } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { searchTopics } from '../../actions/topicsActions';
import Accordion from '../Accordion';
import KeywordsFilter from './KeywordsFilter';
import { Link } from 'react-router-dom';
import { paths } from '../../paths';
import { resetServerStore } from '../../actions/serverStoreActions';
import { BsBookmarkCheckFill as IssueTrackedIcon } from 'react-icons/bs';
import { UncontrolledTooltip } from 'reactstrap';

interface IssueSelectProps {
  title?: string;
  matchesStoreId: string;
  issuesStoreId: string;
  onChange?: any;
  selectedIssues?: string[];
}

const IssueSelect = (props: IssueSelectProps) => {
  const dispatch = useAppDispatch();

  const { title, onChange, matchesStoreId, issuesStoreId } = props;

  const [keywords, setKeywords] = useState('');
  const [showMatches, setShowMatches] = useState(false);

  const issueList = useAppSelector((s) => s.serverStores?.[issuesStoreId]?.payload?.results) || [];

  const issueLookup = issueList
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .reduce((dict: { [key: string]: string }, issue: any) => {
      dict[issue.id] = issue.name;
      return dict;
    }, {});

  const selectedIssues = props.selectedIssues || [];
  const selectedIssuesString = selectedIssues.join(' ');
  let _title = title;

  const matches: Topic[] = useAppSelector((s) => s.serverStores?.[matchesStoreId]?.payload?.results) || [];

  const [isOpen, setIsOpen] = useState(true);
  const onToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectedIssuesString?.length) {
      const _selectedIssues = selectedIssuesString.split(' ');
      if (_selectedIssues?.length > 0) {
        dispatch(
          searchTopics(issuesStoreId, {
            limit: _selectedIssues.length,
            search: { ids: _selectedIssues },
          }),
        );
      }
    } else {
      dispatch(resetServerStore(issuesStoreId));
    }
  }, [selectedIssuesString, issuesStoreId, dispatch]);

  useEffect(() => {
    if (keywords?.length) {
      const _selectedIssues = selectedIssuesString.split(' ');
      dispatch(searchTopics(matchesStoreId, { limit: 20, search: { name: keywords, not_ids: _selectedIssues } }));
      setShowMatches(true);
    } else {
      setShowMatches(false);
    }
  }, [keywords, selectedIssuesString, matchesStoreId, dispatch]);

  const onRemoveIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    onChange('remove', issueId);
  };

  const onAddIssue = (event: any, issue: Topic) => {
    event.stopPropagation();
    onChange('add', issue.id);
    setKeywords('');
  };

  const handleKeywordBlur = () => {
    // This weird function allows us to have an onBlur on the keyword filter that doesn't
    // immediately close the dropdown before the onClick happens.  It's a polish thing
    // for sure, but now we can click away from the dropdown and it will close instead of
    // staying open forever.
    setTimeout(() => {
      setKeywords('');
    }, 200);
  };
  _title = _title ? _title : 'Included issues';

  return (
    <Accordion id="accordionIssue" title={_title} isOpen={isOpen} handleToggle={onToggle} className={'mb-3'}>
      {issueLookup
        ? Object.keys(issueLookup).map((i) => (
            <div className="mb-0" key={i}>
              <IssueTrackedIcon
                id={`IssueTrackedIcon${i}`}
                onClick={(e: any) => onRemoveIssueId(e, i)}
                className="mr-2 clickable"
              />
              <UncontrolledTooltip key={`IssueTrackedTooltip${i}`} target={`IssueTrackedIcon${i}`} placement="bottom">
                Remove issue from My Portfolio
              </UncontrolledTooltip>
              <Link to={paths.VIEW_ISSUE.replace(':id', i)}>{issueLookup[i]}</Link>
            </div>
          ))
        : ''}
      <div style={{ position: 'relative' }}>
        <div className="d-flex mt-3">
          <KeywordsFilter
            keywords={keywords}
            placeholder="Enter issue name..."
            onChange={setKeywords}
            onBlur={handleKeywordBlur}
          />
        </div>
        <div
          style={{
            display: showMatches ? 'block' : 'none',
            paddingLeft: '10px',
            paddingRight: '10px',
            position: 'absolute',
            top: '40px',
            border: '1px solid #000',
            zIndex: 50,
            height: '160px',
            width: '350px',
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
          onBlur={(e) => {
            e.stopPropagation();
            setKeywords('');
          }}
        >
          <ul className="nav flex-column">
            {matches.length ? (
              matches.map((i: Topic) => (
                <li key={i.id} className="nav-item clickable">
                  <span
                    className="nav-link tcf-typeahead-option"
                    style={{ cursor: 'default' }}
                    onClick={(e) => onAddIssue(e, i)}
                  >
                    {i.name}
                  </span>
                </li>
              ))
            ) : (
              <li style={{ cursor: 'default' }}>(No matches found)</li>
            )}
          </ul>
        </div>
        <div className="d-flex mt-3">
          <h6 className={'ml-0'}>
            <i>
              <Link to={paths.LIST_ISSUES} title={'View list of all issues'}>
                View all issues...
              </Link>
            </i>
          </h6>
        </div>
      </div>
    </Accordion>
  );
};

export default IssueSelect;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { Query, Sector } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getSector } from '../../actions/sectorsActions';
import { readDocuments } from '../../actions/documentsActions';
import { resetQuery, setQuery } from '../../actions/queryActions';
import CompanyList from '../../components/SearchSideBar/CompanyList';
import IssueList from '../../components/SearchSideBar/IssueList';
import ViewCalendarButton from '../../components/SearchSideBar/ViewCalendarButton';
import { resetServerStore } from '../../actions/serverStoreActions';
import AsyncPage from '../AsyncPage/AsyncPage';
import DocumentPreviewList from '../Document/components/DocumentPreviewList';
interface SectorRelated {
  sector: Sector;
  companies: { id: string; name: string }[];
  currentTopics: { id: string; name: string }[];
}

const ARTICLES_STORE = 'viewSectorArticlesStore';
const ARTICLES_QUERY = 'viewSectorArticlesQuery';
const SECTOR_RELATED_STORE = 'viewSectorRelatedStore';

const ViewSector = () => {
  const dispatch = useAppDispatch();
  const { id: urlCode } = useParams<{ id: string }>();

  const { pathname, state }: any = useLocation();
  const history = useHistory();
  const stateString = JSON.stringify(state || {});

  const sectorRelatedStore = useAppSelector((s) => s.serverStores?.[SECTOR_RELATED_STORE]);
  const sectorRelated: SectorRelated = sectorRelatedStore?.payload?.results;
  const error: string = sectorRelatedStore?.error;
  const isFetching: boolean = sectorRelatedStore?.isFetching;
  const articles = useAppSelector((s) => s.queries?.[ARTICLES_QUERY]?.payload);

  const sectorName = sectorRelated?.sector?.name || 'Sector';
  const [delayRender, setDelayRender] = useState(true);

  const setupQuery = useCallback(
    (q: Query) => {
      q.skip = q.skip || 0;
      q.limit = q.limit || 10;
      q.search = { sectors: [urlCode], forManagedList: false };
    },
    [urlCode],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const q = JSON.parse(stateString);
    setupQuery(q);

    dispatch(getSector(SECTOR_RELATED_STORE, urlCode!));
    dispatch(readDocuments(ARTICLES_STORE, q));
    dispatch(setQuery(ARTICLES_QUERY, q));
    setDelayRender(false);
    return () => {
      dispatch(resetQuery(ARTICLES_QUERY));
      dispatch(resetServerStore(ARTICLES_STORE));
      dispatch(resetServerStore(SECTOR_RELATED_STORE));
    };
  }, [urlCode, stateString, setupQuery, dispatch]);

  const companiesToShow: { id: string; name: string }[] = useMemo(
    () => sectorRelated?.companies?.sort((a, b) => a.name.localeCompare(b.name))?.slice(0, 10) || [],
    [sectorRelated],
  );

  const topicsToShow: { id: string; name: string }[] = useMemo(
    () => sectorRelated?.currentTopics?.sort((a, b) => a.name.localeCompare(b.name))?.slice(0, 10) || [],
    [sectorRelated],
  );

  const onQueryChange = (q: Query) => {
    setupQuery(q);
    history.push(pathname, q);
  };

  const total = articles?.total ?? 0;

  return (
    <AsyncPage title={sectorName} loading={isFetching || delayRender} error={error}>
      <Row>
        <Col sm={12} md={8} lg={9} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className={'mb-2'}>
            {articles == null ? 'Retrieving' : total.toLocaleString()}&nbsp;article{total !== 1 ? 's' : ''} for{' '}
            <strong>{sectorName}</strong>:
          </div>
          <div>
            <DocumentPreviewList queryId={ARTICLES_QUERY} storeId={ARTICLES_STORE} onQueryChange={onQueryChange} />
          </div>
        </Col>
        <Col md={4} lg={3} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <ViewCalendarButton />
          {topicsToShow.length > 0 ? <IssueList topics={topicsToShow} title={`Current Issues in ${sectorName}`} /> : null}
          {companiesToShow?.length ? <CompanyList companies={companiesToShow} title={'Related Companies'} /> : null}
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ViewSector;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loader from 'react-loader';
import { Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';

import { Topic, DocumentType, Query } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getTopic, getTopicRelated } from '../../actions/topicsActions';
import { setQuery, resetQuery } from '../../actions/queryActions';
import { readDocuments } from '../../actions/documentsActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import DocumentList from '../Document/components/DocumentList';
import CompanyList from '../../components/SearchSideBar/CompanyList';
import SectorList from '../../components/SearchSideBar/SectorList';
import ViewCalendarButton from '../../components/SearchSideBar/ViewCalendarButton';
import AsyncPage from '../AsyncPage/AsyncPage';
import ErrorComponent from '../AsyncPage/ErrorComponent';
import { BsBookmark as IssueNotTrackedIcon, BsBookmarkCheckFill as IssueTrackedIcon } from 'react-icons/bs';
import { addTrackedIssue, deleteTrackedIssue, getTrackedIssues } from '../../actions/trackedIssuesActions';

const ARTICLES_STORE = 'viewIssueArticlesStore';
const ARTICLES_QUERY = 'viewIssueArticlesQuery';
const SOURCES_STORE = 'viewIssueSourcesStore';
const SOURCES_QUERY = 'viewIssueSourcesQuery';
const ISSUE_STORE = 'viewIssueStore';
const ISSUE_RELATED_STORE = 'viewIssueRelatedStore';
const ARTICLES_TAB = 'Articles';
const SOURCES_TAB = 'Sources';
const MY_PORTFOLIO_TRACKED_ISSUES_STORE = 'viewIssueTrackedIssuesStore';

interface TopicRelated {
  companies: { id: string; name: string }[];
  topics: { id: string; name: string }[];
  sectors: { code: string; name: string }[];
}

const ViewIssue = () => {
  const dispatch = useAppDispatch();

  const [delayRender, setDelayRender] = useState(true);
  const { id: urlId } = useParams<{ id: string }>();
  const { pathname, state }: any = useLocation();
  const history = useHistory();

  const articlesQueryState = state?.articlesQuery || {};
  const articlesQueryString = JSON.stringify(articlesQueryState);
  const sourcesQueryState = state?.sourcesQuery || {};
  const sourcesQueryString = JSON.stringify(sourcesQueryState);
  const activeTab = state?.activeTab || ARTICLES_TAB;

  const articlesQuery = useAppSelector((s) => s.queries?.[ARTICLES_QUERY]);
  const sourcesQuery = useAppSelector((s) => s.queries?.[SOURCES_QUERY]);
  const topic: Topic = useAppSelector((s) => s.serverStores?.[ISSUE_STORE]?.payload);
  const topicRelated: TopicRelated = useAppSelector((s) => s.serverStores?.[ISSUE_RELATED_STORE]?.payload?.results);
  const sourcesPayload = useAppSelector((s) => s.serverStores?.[SOURCES_STORE]?.payload);
  const error: string = useAppSelector(
    (s) => s.serverStores?.[ISSUE_STORE]?.error || s.serverStores?.[ISSUE_RELATED_STORE]?.error,
  );
  const isFetching: boolean = useAppSelector(
    (s) => s.serverStores?.[ISSUE_STORE]?.isFetching || s.serverStores?.[ISSUE_RELATED_STORE]?.isFetching,
  );
  const trackedIssuesFromUser = useAppSelector((s) => s.serverStores?.[MY_PORTFOLIO_TRACKED_ISSUES_STORE]);
  const setupArticlesQuery = useCallback(
    (q: Query) => {
      q.skip = q.skip || 0;
      q.limit = q.limit || 10;
      q.search = { includeIssues: [urlId], documentTypes: [DocumentType.article, DocumentType.transcript] };
    },
    [urlId],
  );

  const setupSourcesQuery = useCallback(
    (q: Query) => {
      q.skip = q.skip || 0;
      q.limit = q.limit || 10;
      q.search = { includeIssues: [urlId], documentTypes: [DocumentType.source] };
    },
    [urlId],
  );

  useEffect(() => {
    dispatch(getTrackedIssues(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
    return () => {
      dispatch(resetServerStore(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTopic(ISSUE_STORE, urlId!));
    dispatch(getTopicRelated(ISSUE_RELATED_STORE, urlId!));
    setDelayRender(false);
    return () => {
      dispatch(resetServerStore(ISSUE_STORE));
      dispatch(resetServerStore(ISSUE_RELATED_STORE));
    };
  }, [urlId, dispatch]);

  useEffect(() => {
    const q = JSON.parse(articlesQueryString);
    setupArticlesQuery(q);
    dispatch(readDocuments(ARTICLES_STORE, q));
    dispatch(setQuery(ARTICLES_QUERY, q));
    return () => {
      dispatch(resetQuery(ARTICLES_QUERY));
      dispatch(resetServerStore(ARTICLES_STORE));
    };
  }, [urlId, articlesQueryString, setupArticlesQuery, dispatch]);

  useEffect(() => {
    const q = JSON.parse(sourcesQueryString);
    setupSourcesQuery(q);
    dispatch(readDocuments(SOURCES_STORE, q));
    dispatch(setQuery(SOURCES_QUERY, q));
    return () => {
      dispatch(resetQuery(SOURCES_QUERY));
      dispatch(resetServerStore(SOURCES_STORE));
    };
  }, [urlId, sourcesQueryString, setupSourcesQuery, dispatch]);

  const companiesToShow: { id: string; name: string }[] =
    topicRelated?.companies?.sort((a: any, b: any) => a.name.localeCompare(b.name)).slice(0, 10) || [];

  const sectorsToShow: { code: string; name: string }[] =
    topicRelated?.sectors?.sort((a: any, b: any) => a.name.localeCompare(b.name)).slice(0, 10) || [];

  if (isFetching || delayRender) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (!topic) {
    return null;
  }

  const onToggleTab = (tab: string) => {
    if (activeTab !== tab) {
      history.push(pathname, { articlesQuery, sourcesQuery, activeTab: tab });
    }
  };

  const onSourcesPageChange = (q: Query) => {
    setupSourcesQuery(q);
    history.push(pathname, { articlesQuery, sourcesQuery: q, activeTab });
  };

  const onArticlesPageChange = (q: Query) => {
    setupArticlesQuery(q);
    history.push(pathname, { articlesQuery: q, sourcesQuery, activeTab });
  };

  const articlesList = <DocumentList queryId={ARTICLES_QUERY} storeId={ARTICLES_STORE} onQueryChange={onArticlesPageChange} />;

  const haveSourceDocuments = !!sourcesPayload?.total;

  if (trackedIssuesFromUser?.isFetching) {
    return null;
  }
  const trackedIssues = trackedIssuesFromUser?.payload;

  const handleRemoveTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(deleteTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
  };
  const handleAddTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(addTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
  };

  const trackedIssueIconFormatter = (topicId: string) => {
    return (
      <span>
        {trackedIssues[topic.id] ? (
          <span>
            <IssueTrackedIcon
              key="trackedIssueIcon"
              id="trackedIssueIcon"
              onClick={(e) => handleRemoveTrackedIssueId(e, topicId)}
              aria-label="Remove issue from my portfolio"
              className="mr-2 clickable"
            />{' '}
            <UncontrolledTooltip key="trackedIssueTooltip" target={'trackedIssueIcon'} placement="bottom">
              Remove issue from My Portfolio
            </UncontrolledTooltip>
          </span>
        ) : (
          <span>
            <IssueNotTrackedIcon
              key="untrackedIssueIcon"
              id="untrackedIssueIcon"
              onClick={(e) => handleAddTrackedIssueId(e, topicId)}
              aria-label="Remove issue from my portfolio"
              className="mr-2 clickable"
            />{' '}
            <UncontrolledTooltip key="untrackedIssueTooltip" target={'untrackedIssueIcon'} placement="bottom">
              Add issue to My Portfolio
            </UncontrolledTooltip>
            <small>(Bookmark this Issue to add to My Porfolio)</small>
          </span>
        )}
      </span>
    );
  };

  return (
    <AsyncPage title={topic.name} loading={isFetching} error={error}>
      <Row>
        <Col sm={12} md={8} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className={'mb-2'}>
            Articles {haveSourceDocuments ? 'and source documents' : ''} for issue&nbsp;
            <span className="">
              <strong>
                {topic.name} {trackedIssueIconFormatter(topic.id)}
              </strong>
            </span>
          </div>
          {haveSourceDocuments && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    className={activeTab === ARTICLES_TAB ? 'active' : ''}
                    onClick={() => onToggleTab(ARTICLES_TAB)}
                    title={'View articles tagged with issue'}
                  >
                    Articles
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={activeTab === SOURCES_TAB ? 'active' : ''}
                    onClick={() => onToggleTab(SOURCES_TAB)}
                    title={'View source documents related to issue'}
                  >
                    Source Documents
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={ARTICLES_TAB}>
                  {topic && activeTab === ARTICLES_TAB && <div className={'mt-2'}>{articlesList}</div>}
                </TabPane>
                <TabPane tabId={SOURCES_TAB}>
                  {topic && activeTab === SOURCES_TAB && (
                    <div className={'mt-2'}>
                      <DocumentList queryId={SOURCES_QUERY} storeId={SOURCES_STORE} onQueryChange={onSourcesPageChange} />
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </>
          )}
          {!haveSourceDocuments && <div className={'mt-2'}>{articlesList}</div>}
        </Col>
        <Col md={4} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <div>
            <ViewCalendarButton />
            {companiesToShow.length > 0 ? <CompanyList companies={companiesToShow} title={'Related Companies'} /> : null}
            {sectorsToShow.length > 0 ? <SectorList sectors={sectorsToShow} title={'Related Sectors'} /> : null}
          </div>
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ViewIssue;

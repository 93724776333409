import React, { useEffect } from 'react';

import { AuthUser, Company, Query, Category, Topic } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { searchSavedSearches } from '../../actions/savedSearchesActions';
import { searchTopics } from '../../actions/topicsActions';
import { searchCompanies } from '../../actions/companiesActions';
import CompanyList from './CompanyList';
import IssueListDataController from './IssueList';
import DocumentList from './DocumentList';
import ViewCalendarButton from './ViewCalendarButton';
import SavedSearchNameList from '../../pages/SavedSearch/components/SavedSearchNameList';
import { TOP_DOCS_AND_TOPICS_STORE } from '../../storeIds';
import AnnouncementList from './AnnouncementList';

const CURRENT_CATEGORY_TOPICS_STORE = 'searchSideBarCurrentCategoryTopicsStore';
const COMPANIES_STORE = 'searchSideBarCompaniesStore';

interface OwnProps {
  authUser: AuthUser;
  category?: Category;
  savedSearchesStoreId: string;
}

type SearchSideBarProps = OwnProps;

const SearchSideBar = (props: SearchSideBarProps) => {
  const dispatch = useAppDispatch();
  const { authUser, category, savedSearchesStoreId } = props;
  const userId = authUser.id;
  const categoryId = category?.id;
  const savedSearches = useAppSelector((state) => state.serverStores?.[savedSearchesStoreId]?.payload?.results) || [];
  const currentCategoryTopics: Topic[] =
    useAppSelector((state) => state.serverStores?.[CURRENT_CATEGORY_TOPICS_STORE]?.payload?.results) || [];
  const defaultCompanies: Company[] = useAppSelector((state) => state.serverStores?.[COMPANIES_STORE]?.payload?.results) || [];

  const topDocsAndTopicsStore = useAppSelector((state) => state.serverStores?.[TOP_DOCS_AND_TOPICS_STORE]);
  const topDocuments: {
    all: { id: string; title: string }[];
    investigations: { id: string; title: string }[];
    mergers: { id: string; title: string }[];
    antitrust: { id: string; title: string }[];
  } = topDocsAndTopicsStore?.payload?.topDocuments || {};
  const topTopics: { id: string; name: string }[] = topDocsAndTopicsStore?.payload?.topTopics || [];

  useEffect(() => {
    if (categoryId) {
      const q: Query = {
        limit: 10,
        search: { categories: [categoryId], current: true },
      };
      dispatch(searchTopics(CURRENT_CATEGORY_TOPICS_STORE, q));
    } else {
      dispatch(searchSavedSearches(savedSearchesStoreId, { search: { userId } }));
      dispatch(searchCompanies(COMPANIES_STORE, { limit: 10, search: { withArticlesOnly: true } }));
    }
  }, [userId, categoryId, savedSearchesStoreId, dispatch]);

  let companiesToShow: { id: string; name: string }[] = [];
  // Do we want to show companies relevant to selected category?
  // if (category) {
  //   if (currentCategoryTopics?.length) {
  //     // Derive unique set of companies represented in category current topics by adding to a dict, then converting
  //     // to a list.
  //     const companyLookup: { [id: string]: string } = {};
  //     currentCategoryTopics.forEach((topic: any) => {
  //       topic.companies?.forEach((company: any) => {
  //         companyLookup[company.id] = company.name;
  //       });
  //     });
  //     companiesToShow = Object.entries(companyLookup).map(([id, name]) => ({ id, name }));
  //     companiesToShow.sort((a, b) => a.name.localeCompare(b.name));
  //   }
  // }
  if (!companiesToShow.length) {
    companiesToShow = defaultCompanies;
  }

  // Avoid including multi-category documents in multiple lists
  const popularInAntitrust = topDocuments?.antitrust?.slice(0, category ? 10 : 4) || [];
  const popularInInvestigations =
    topDocuments?.investigations
      ?.filter((d) => (category ? true : !popularInAntitrust.find((a) => a.id === d.id)))
      ?.slice(0, category ? 10 : 4) || [];
  const popularInMergers =
    topDocuments?.mergers
      ?.filter((d) =>
        category ? true : !popularInAntitrust.find((a) => a.id === d.id) && !popularInInvestigations.find((a) => a.id === d.id),
      )
      ?.slice(0, category ? 10 : 4) || [];

  const categoryLimits = authUser?.categoryIds;
  const noCategoryLimits = !categoryLimits?.length;

  return (
    <div>
      <ViewCalendarButton />
      {!category && <SavedSearchNameList savedSearches={savedSearches} />}
      <AnnouncementList />
      {category ? (
        <IssueListDataController topics={currentCategoryTopics} title={`Current Issues in ${category.name}`} />
      ) : (
        <IssueListDataController topics={topTopics} />
      )}

      {(noCategoryLimits || categoryLimits.includes('mergers')) && (!category || category.id === 'mergers') && (
        <DocumentList documents={popularInMergers} title={'Popular in M&A'} />
      )}
      {(noCategoryLimits || categoryLimits.includes('investigations')) && (!category || category.id === 'investigations') && (
        <DocumentList documents={popularInInvestigations} title={'Popular in Corporate Investigations'} />
      )}
      {(noCategoryLimits || categoryLimits.includes('antitrust')) && (!category || category.id === 'antitrust') && (
        <DocumentList documents={popularInAntitrust} title={'Popular in Antitrust'} />
      )}
      {!category && <CompanyList companies={companiesToShow} title="Companies" />}
    </div>
  );
};

export default SearchSideBar;

import { READ_SERVER, SAVE_SERVER } from './actionTypes';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';
import { AsyncActionType } from './getAsyncActionTypes';

export function getTrackedIssues(storeIdentifier: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.trackedIssues.getList()).data,
    storeIdentifier,
  });
}

export function deleteTrackedIssue(storeIdentifier: string, id: string, actionType: AsyncActionType = SAVE_SERVER) {
  return asyncAction({
    actionType,
    storeIdentifier,
    func: async () => (await library.trackedIssues.simpleDelete(id)).data,
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message:
            'Unable to delete TrackedIssue.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}
export function addTrackedIssue(storeIdentifier: string, id: string, actionType: AsyncActionType = SAVE_SERVER) {
  return asyncAction({
    actionType,
    storeIdentifier,
    func: async () => (await library.trackedIssues.put(id, {})).data,
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message:
            'Unable to add TrackedIssue.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}

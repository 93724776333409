import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import { DocumentSearch, Query } from 'tcf-shared/models';

import { BsBookmark as IssueNotTrackedIcon } from 'react-icons/bs';
import { getAuthUser } from '../../reducers/authReducer';
import { addTrackedIssue, deleteTrackedIssue, getTrackedIssues } from '../../actions/trackedIssuesActions';
import { MY_PORTFOLIO_TRACKED_ISSUES_STORE } from '../../storeIds';
import { NotFoundError } from '../../components';
import { readDocuments } from '../../actions/documentsActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import { useAppDispatch, useAppSelector, useLocationHistoryQuery } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import DocumentPreviewList from '../Document/components/DocumentPreviewList';
import IssueSelect from '../../components/filters/IssueSelect';

const MY_PORTFOLIO_TITLE = 'My Portfolio';

const ARTICLES_STORE = 'myPortfolioArticlesStore';
const ISSUES_MATCHES_STORE = 'myPortfolioFilterIssueMatchesStore';
const INCLUDE_ISSUES_STORE = 'myPortfolioFilterIssueListStore';

const MyPortfolio = () => {
  const dispatch = useAppDispatch();

  const [query, replaceQuery, , , , error] = useLocationHistoryQuery<Query>(
    'myPortfolioArticlesQuery',
    ARTICLES_STORE,
    (locationQuery?: Partial<Query>) => ({
      skip: 0,
      limit: 10,
      ...locationQuery,
    }),
  );
  const queryString = JSON.stringify(query);
  const articleStorePayload = useAppSelector((state) => state.serverStores?.[ARTICLES_STORE]?.payload);
  const articleStoreQuery = articleStorePayload?.query;
  const myPortfolioTrackedIssues = useAppSelector((state) => state.serverStores?.[MY_PORTFOLIO_TRACKED_ISSUES_STORE]);
  const myPortfolioTrackedIssuesNotReady = myPortfolioTrackedIssues?.isFetching || !myPortfolioTrackedIssues?.payload;

  const trackedIssues = myPortfolioTrackedIssues?.payload ? Object.keys(myPortfolioTrackedIssues?.payload) : [];
  const trackedIssuesString = JSON.stringify(trackedIssues);

  const authUser = useAppSelector((s) => getAuthUser(s));
  useEffect(() => {
    dispatch(getTrackedIssues(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
    return () => {
      dispatch(resetServerStore(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
      dispatch(resetServerStore(ARTICLES_STORE));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!myPortfolioTrackedIssuesNotReady) {
      const _query = JSON.parse(queryString);
      const _trackedIssues = JSON.parse(trackedIssuesString);
      const _sortBy = _query?.search?.sortBy || 'publicationDate';
      const _sortOrder = _query?.search?.sortOrder || 'desc';
      dispatch(
        readDocuments(ARTICLES_STORE, {
          ..._query,
          search: { sortby: _sortBy, sortOrder: _sortOrder, includeIssues: _trackedIssues },
        }),
      );
    }
  }, [queryString, trackedIssuesString, myPortfolioTrackedIssuesNotReady, dispatch]);

  const [sortDropDownOpen, setSortDropDownOpen] = useState(false);
  const toggleSortDropDown = () => setSortDropDownOpen((prevState) => !prevState);

  if (myPortfolioTrackedIssuesNotReady) return null;
  if (!authUser) return <NotFoundError />;

  const myPortfolioEmptyTrackedIssuesListText = (
    <span>
      {' '}
      The 'My Portfolio' feature allows you to easily track articles related to the issues that are most important to you. Use
      the search box in the left sidebar to find issues you'd like to follow, or browse the full list of issues by clicking the
      link at the bottom of the "Tracked Issues" panel. Anywhere you see the{' '}
      <IssueNotTrackedIcon title="Add issue to My Portfolio" /> icon you can click it to add that issue to your Portfolio.
    </span>
  );

  const onSortBy = (event: any, sort: string) => {
    event.stopPropagation();
    const _search: DocumentSearch = { ...query?.search };
    if (sort === 'oldest') {
      _search.sortBy = 'publicationDate';
      _search.sortOrder = 'asc';
    } else {
      // newest
      _search.sortBy = 'publicationDate';
      _search.sortOrder = 'desc';
    }
    replaceQuery({ ...query, skip: 0, search: _search });
  };
  const handleIssueFilterChange = (action: string, issueId: string) => {
    if (action === 'add') {
      dispatch(addTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
    } else if (action === 'remove') {
      dispatch(deleteTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
    }
  };

  return (
    <AsyncPage title={MY_PORTFOLIO_TITLE} error={error}>
      <Row>
        <Col md={3} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <IssueSelect
            key={trackedIssues?.toString()}
            title="Tracked Issues"
            matchesStoreId={ISSUES_MATCHES_STORE}
            issuesStoreId={INCLUDE_ISSUES_STORE}
            selectedIssues={trackedIssues}
            onChange={handleIssueFilterChange}
          />
        </Col>
        <Col md={9} className={'py-3 px-3'} style={{ backgroundColor: '#fff' }}>
          <div className="float-right position-relative mr-5">
            {trackedIssues?.length > 0 ? (
              <Dropdown className={'d-inline'} isOpen={sortDropDownOpen} toggle={toggleSortDropDown}>
                <DropdownToggle caret>Sort</DropdownToggle>
                <DropdownMenu className="" style={{ minWidth: '125px' }}>
                  <DropdownItem
                    active={
                      (query?.search?.sortBy || 'publicationDate') === 'publicationDate' &&
                      (query?.search?.sortOrder || 'desc') === 'desc'
                    }
                    onClick={(e) => onSortBy(e, 'newest')}
                  >
                    Newest
                  </DropdownItem>
                  <DropdownItem
                    active={
                      (query?.search?.sortBy || 'publicationDate') === 'publicationDate' && query?.search?.sortOrder === 'asc'
                    }
                    onClick={(e) => onSortBy(e, 'oldest')}
                  >
                    Oldest
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null}
          </div>
          <h4>Documents</h4>
          <div className={'clearfix'} />
          {trackedIssues?.length > 0 ? (
            <DocumentPreviewList
              hideSearchSummary
              query={articleStoreQuery}
              storeId={ARTICLES_STORE}
              onQueryChange={replaceQuery}
              includeIssuesStoreId={MY_PORTFOLIO_TRACKED_ISSUES_STORE}
              showCurrentPageBeyondRange={true}
            />
          ) : (
            myPortfolioEmptyTrackedIssuesListText
          )}
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default MyPortfolio;

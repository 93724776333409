import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardSubtitle, UncontrolledTooltip } from 'reactstrap';
import { BsBookmark as IssueNotTrackedIcon, BsBookmarkCheckFill as IssueTrackedIcon } from 'react-icons/bs';
import { paths } from '../../paths';
import { addTrackedIssue, deleteTrackedIssue, getTrackedIssues } from '../../actions/trackedIssuesActions';
import { resetServerStore } from '../../actions/serverStoreActions';

interface IssueListProps {
  topics: { id: string; name: string; tracked?: boolean }[];
  title?: string;
  instructionalText?: string;
  trackedIssuesStoreName: string;
  emptyListText?: React.ReactNode;
}

interface IssueListTableDataControllerProps {
  topics: { id: string; name: string }[];
  title?: string;
  instructionalText?: string;
  emptyListText?: React.ReactNode;
  trackedIssuesStoreName?: string;
}
const ISSUE_LIST_TRACKED_ISSUES_STORE = 'issueListPortfolioTrackedIssues';

const IssueListDataController = (props: IssueListTableDataControllerProps) => {
  const { topics, title, instructionalText, emptyListText, trackedIssuesStoreName } = props;
  const dispatch = useAppDispatch();

  const TRACKED_ISSUES_STORE = trackedIssuesStoreName || ISSUE_LIST_TRACKED_ISSUES_STORE;

  useEffect(() => {
    if (!trackedIssuesStoreName) {
      dispatch(getTrackedIssues(ISSUE_LIST_TRACKED_ISSUES_STORE));
      return () => {
        dispatch(resetServerStore(ISSUE_LIST_TRACKED_ISSUES_STORE));
      };
    }
  }, [trackedIssuesStoreName, dispatch]);

  const myPortfolioTrackedIssues = useAppSelector((state) => state.serverStores?.[TRACKED_ISSUES_STORE]);
  if (!myPortfolioTrackedIssues?.payload || myPortfolioTrackedIssues?.isFetching) {
    return null;
  }
  const trackedIssuesLookup = myPortfolioTrackedIssues?.payload;
  const trackedTopics = topics
    ?.map((topic: any) => ({
      id: topic.id,
      name: topic.name,
      tracked: !!trackedIssuesLookup[topic.id],
    }))
    .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name;

  return (
    <IssueList
      {...props}
      title={title}
      instructionalText={instructionalText}
      topics={trackedTopics || []}
      trackedIssuesStoreName={TRACKED_ISSUES_STORE}
      emptyListText={emptyListText}
    />
  );
};

const IssueList = (props: IssueListProps) => {
  const { topics, title, instructionalText, trackedIssuesStoreName, emptyListText } = props;
  const dispatch = useAppDispatch();

  const handleRemoveTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(deleteTrackedIssue(trackedIssuesStoreName, issueId));
  };
  const handleAddTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(addTrackedIssue(trackedIssuesStoreName, issueId));
  };

  return (
    <Card className={'mb-3'}>
      <CardHeader>{title || 'Popular Issues'}</CardHeader>
      <CardBody>
        {!topics.length && <div>{emptyListText}</div>}
        {instructionalText && (
          <CardSubtitle className="mb-2 text-highlight" tag="h6">
            {instructionalText}
          </CardSubtitle>
        )}
        {topics.length > 0 && (
          <ul className={'pl-0 list-unstyled'}>
            {topics.map((t) => (
              <li key={t.id}>
                {t.tracked ? (
                  <span>
                    <IssueTrackedIcon
                      id={`TrackedIssueIcon${t.id}`}
                      onClick={(e) => handleRemoveTrackedIssueId(e, t.id)}
                      className="mr-2 clickable"
                    />
                    <UncontrolledTooltip key={`IssueToolTip${t.id}`} target={`TrackedIssueIcon${t.id}`} placement="bottom">
                      Remove issue from My Portfolio
                    </UncontrolledTooltip>
                  </span>
                ) : (
                  <span>
                    <IssueNotTrackedIcon
                      id={`NotTrackedIssueIcon${t.id}`}
                      onClick={(e) => handleAddTrackedIssueId(e, t.id)}
                      className="mr-2 clickable"
                    />
                    <UncontrolledTooltip
                      key={`NotTrackedIssueToolTip${t.id}`}
                      target={`NotTrackedIssueIcon${t.id}`}
                      placement="bottom"
                    >
                      Add issue to My Portfolio
                    </UncontrolledTooltip>
                  </span>
                )}
                <Link to={paths.VIEW_ISSUE.replace(':id', t.id)}>{t.name}</Link>
              </li>
            ))}
          </ul>
        )}
        <h6 className={'ml-0'}>
          <i>
            <Link to={paths.LIST_ISSUES} title={'View list of all issues'}>
              View all issues...
            </Link>
          </i>
        </h6>
      </CardBody>
    </Card>
  );
};

export default IssueListDataController;

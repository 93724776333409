import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';

import {
  allCategoriesTableFilterOptions,
  allRegionsTableFilterOptions,
  allSectorsTableFilterOptions,
  Query,
  Topic,
  UserSearch,
} from 'tcf-shared/models';

import { paths } from '../../../paths';
import { getAuthUser } from '../../../reducers/authReducer';
import { useAppSelector, useAppDispatch } from '../../../utils/hooks';
import { formatVeryShortDate } from '../../../utils/momentFormat';
import TcfBootstrapSearchTable, {
  getDefaultSorted,
  getPagingParamsForTable,
  TableState,
} from '../../../components/TcfBootstrapSearchTable';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { Icon } from '../../../components';
import { BsBookmark as IssueNotTrackedIcon, BsBookmarkCheckFill as IssueTrackedIcon } from 'react-icons/bs';
import { addTrackedIssue, deleteTrackedIssue, getTrackedIssues } from '../../../actions/trackedIssuesActions';
import { resetServerStore } from '../../../actions/serverStoreActions';
import { UncontrolledTooltip } from 'reactstrap';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_SORT_BY = 'name';
export const DEFAULT_SORT_ORDER = 'asc';
const MY_PORTFOLIO_TRACKED_ISSUES_STORE = 'issueListMyPortfolioTrackedIssuesStore';
interface IssueListTableDataControllerProps {
  manageIssues?: boolean;
  listIssueStoreId: string;
  query: Query;
  onQueryChange: (query: Query, debounce?: boolean) => void;
}

interface IssueListProps extends IssueListTableDataControllerProps {
  trackedIssues: { [key: string]: string };
  trackedIssuesSearchLastUpdatedDate?: string;
}

const IssueListTableDataController = (props: IssueListTableDataControllerProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTrackedIssues(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
    return () => {
      dispatch(resetServerStore(MY_PORTFOLIO_TRACKED_ISSUES_STORE));
    };
  }, [dispatch]);

  const trackedIssuesFromUser = useAppSelector((state) => state.serverStores?.[MY_PORTFOLIO_TRACKED_ISSUES_STORE]);
  if (trackedIssuesFromUser?.isFetching) {
    return null;
  }
  const trackedIssues = trackedIssuesFromUser?.payload;
  return (
    <IssueList
      {...props}
      trackedIssues={trackedIssues}
      trackedIssuesSearchLastUpdatedDate={String(Object.keys(trackedIssues || {}).length)}
    />
  );
};

const IssueList = (props: IssueListProps) => {
  const {
    manageIssues,
    listIssueStoreId,
    query,
    onQueryChange,
    trackedIssues: originalTrackedIssues,
    trackedIssuesSearchLastUpdatedDate,
  } = props;

  const dispatch = useAppDispatch();

  const trackedIssues = originalTrackedIssues || [];

  const authUser = useAppSelector((s) => getAuthUser(s));
  const isAdmin = authUser?.isAdmin;
  const isStaff = authUser?.isStaff;

  const serverStore = useAppSelector((state) => state.serverStores?.[listIssueStoreId]);
  const payload = serverStore?.payload;
  const error = serverStore?.error;
  const isFetching = serverStore?.isFetching ?? true;
  const isDeleting = serverStore?.isDeleting;

  if (error) return <ErrorComponent error={error} />;
  if (isFetching || isDeleting) return <Loader loaded={false} />;

  const { currentPage, limit, totalSize, sortBy, sortOrder } = getPagingParamsForTable(
    payload,
    query,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_BY,
    DEFAULT_SORT_ORDER,
  );

  const onTableChange = (type: unknown, newState: TableState) => {
    const { page, sizePerPage, sortField, sortOrder: _sortOrder } = newState;
    const _search: UserSearch = { ...query?.search };
    const _sortBy = sortField || query?.search?.sortBy || DEFAULT_SORT_BY;
    const _finalSortOrder = _sortOrder || query?.search?.sortOrder || DEFAULT_SORT_ORDER;
    const skip = sizePerPage * (page - 1);
    if (
      query.skip !== skip ||
      query.limit !== sizePerPage ||
      (_sortBy !== _search.sortBy && !(_sortBy === DEFAULT_SORT_BY && _search.sortBy == null)) ||
      (_finalSortOrder !== _search.sortOrder && !(_finalSortOrder === DEFAULT_SORT_ORDER && _search.sortOrder == null))
    ) {
      _search.sortBy = _sortBy;
      _search.sortOrder = _finalSortOrder;
      onQueryChange({ ...query, skip, limit: sizePerPage, search: _search });
    }
  };

  const handleRemoveTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(deleteTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
  };
  const handleAddTrackedIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    dispatch(addTrackedIssue(MY_PORTFOLIO_TRACKED_ISSUES_STORE, issueId));
  };

  const actionsFormatter = (cell: string) => {
    return (
      <Link to={paths.admin.EDIT_ISSUE.replace(':id', cell)} title={'Edit issue'}>
        Edit
      </Link>
    );
  };

  const formatName = (cell: string, row: any) => (
    <Link to={paths.VIEW_ISSUE.replace(':id', row.id)} title={'View issue'}>
      {cell}
    </Link>
  );

  const formatSectors = (cell: { code: string }[], row: Topic) =>
    cell?.length ? (
      <small>
        {cell
          .map((cx) => allSectorsTableFilterOptions.find((c) => c.value === cx.code)?.label || '')
          .sort()
          .join('; ')}
      </small>
    ) : null;

  const formatCategories = (cell: { id: string }[]) =>
    cell && cell.length > 0 ? (
      <small>
        {Array.from(
          new Set(cell.map((cx) => allCategoriesTableFilterOptions.find((c) => c.value === cx.id)?.label || '')),
        ).join('; ')}
      </small>
    ) : null;

  const formatRegions = (cell: { id: string }[]) =>
    cell && cell.length > 0 ? (
      <small>{cell.map((cx) => allRegionsTableFilterOptions.find((c) => c.value === cx.id)?.label || '').join('; ')}</small>
    ) : null;

  const formatCompanies = (cell: { id: string; name: string }[]) =>
    cell && cell.length > 0 ? <small>{cell.map((cx) => cx.name).join('; ')}</small> : null;

  const check = <Icon name={'check'} size={20} className={'text-success'} />;
  const formatBoolean = (cell: boolean) => (cell ? check : null);

  const formatCreatedAt = (cell: any, row: Topic) => formatVeryShortDate(row.meta.createdAt);

  const trackedIssueFormatter = (cell: boolean, row: any) => {
    return (
      <span>
        {trackedIssues[row.id as string] ? (
          <span>
            <IssueTrackedIcon
              id={`TrackedIssue${row.id}`}
              onClick={(e) => handleRemoveTrackedIssueId(e, row.id)}
              className="mr-2 clickable"
            />
            <UncontrolledTooltip key={`TrackedIssueTooltip${row.id}`} target={`TrackedIssue${row.id}`} placement="bottom">
              Remove issue from My Portfolio
            </UncontrolledTooltip>
          </span>
        ) : (
          <span>
            <IssueNotTrackedIcon
              id={`NotTrackedIssue${row.id}`}
              onClick={(e) => handleAddTrackedIssueId(e, row.id)}
              className="mr-2 clickable"
            />
            <UncontrolledTooltip key={`NotTrackedIssueTooltip${row.id}`} target={`NotTrackedIssue${row.id}`} placement="bottom">
              Add issue to My Portfolio
            </UncontrolledTooltip>
          </span>
        )}
      </span>
    );
  };

  let columns: any[] = [];
  if (manageIssues && isAdmin) {
    columns.push({
      // isDummyField: true,
      dataField: 'id',
      text: 'Actions',
      formatter: actionsFormatter,
      headerStyle: { width: '7%' },
      align: 'left',
      headerAlign: 'left',
    });
  } else {
    columns.push({
      dataField: 'isTracked',
      text: '',
      align: 'center',
      headerAlign: 'center',
      sort: false,
      formatter: trackedIssueFormatter,
      headerStyle: { width: '3%' },
    });
  }
  columns = columns.concat([
    {
      dataField: 'name',
      text: 'Issue',
      formatter: formatName,
      sort: true,
      headerStyle: { width: '20%' },
    },
    {
      dataField: 'companies',
      text: 'Companies',
      formatter: formatCompanies,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'categories',
      text: 'Categories',
      formatter: formatCategories,
      sort: manageIssues,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'regions',
      text: 'Regions',
      formatter: formatRegions,
      sort: manageIssues,
      headerStyle: { width: '13%' },
    },
    {
      dataField: 'sectors',
      text: 'Sectors',
      formatter: formatSectors,
      sort: manageIssues,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'current',
      text: 'Current?',
      formatter: formatBoolean,
      align: 'center',
      headerAlign: 'center',
      sort: true,
      headerStyle: { width: '6%' },
    },
  ]);

  if (manageIssues && (isStaff || isAdmin)) {
    columns.push({
      dataField: 'efficyKey',
      text: 'Efficy ID',
      align: 'center',
      sort: true,
      headerStyle: { width: '7%' },
    });
    columns.push({
      dataField: 'createdAt',
      text: 'Added',
      formatter: formatCreatedAt,
      align: 'right',
      sort: true,
      headerAlign: 'right',
      headerStyle: { width: '9%' },
    });
  }

  return !payload?.total ? (
    <p>No matching issues found</p>
  ) : (
    <>
      <div>{totalSize?.toLocaleString() || 0} Issues Found</div>
      <TcfBootstrapSearchTable
        key={trackedIssuesSearchLastUpdatedDate}
        allowPaging
        allowWrap
        columns={columns}
        dataSource={payload?.results || []}
        defaultSorted={getDefaultSorted(sortBy, sortOrder)}
        keyField="id"
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={totalSize}
      />
    </>
  );
};

export default IssueListTableDataController;

import { axios } from './axios';

import { AuthApiClient } from './AuthApiClient';
import { AuthorsApiClient } from './AuthorsApiClient';
import { CompaniesClient } from './CompaniesClient';
import { CrudApiClient } from './CrudApiClient';
import { DirectoryEntityApiClient } from './DirectoryEntityApiClient';
import { DocumentsApiClient } from './DocumentsApiClient';
import { DocViewsApiClient } from './DocViewsApiClient';
import { EmailCampaignsApiClient } from './EmailCampaignApiClient';
import { EmailServiceApiClient } from './EmailServiceApiClient';
import { OrganizationsApiClient } from './OrganizationsApiClient';
import { RegionsApiClient } from './RegionsApiClient';
import { SectorsApiClient } from './SectorsApiClient';
import { StartupApiClient } from './StartupApiClient';
import { StockExchangesApiClient } from './StockExchangesApiClient';
import { UsageApiClient } from './UsageApiClient';
import { UserApiClient } from './UserApiClient';
import { UsersApiClient } from './UsersApiClient';

export const library = {
  auth: new AuthApiClient(axios),
  authors: new AuthorsApiClient(axios),
  calendarEvent: new CrudApiClient(axios, '/calendar-event'),
  candidateCompanies: new CrudApiClient(axios, '/candidate-companies'),
  client: new StartupApiClient(axios),
  companies: new CompaniesClient(axios, '/companies'),
  directoryEntity: new DirectoryEntityApiClient(axios),
  directoryEvidence: new CrudApiClient(axios, '/directory/evidence'),
  directoryEvidenceTag: new CrudApiClient(axios, '/directory/evidence-tag'),
  directoryEvidenceType: new CrudApiClient(axios, '/directory/evidence-type'),
  docViews: new DocViewsApiClient(axios, '/docviews'),
  docs: new DocumentsApiClient(axios),
  emailService: new EmailServiceApiClient(axios),
  emailCampaign: new EmailCampaignsApiClient(axios),
  organizations: new OrganizationsApiClient(axios),
  organizationSelfManagement: new CrudApiClient(axios, '/organization-self-management'),
  regions: new RegionsApiClient(axios, '/regions'),
  savedSearches: new CrudApiClient(axios, '/saved-searches'),
  sectors: new SectorsApiClient(axios, '/sectors'),
  stockExchanges: new StockExchangesApiClient(axios, '/stock-exchanges'),
  topics: new CrudApiClient(axios, '/topics'),
  trackedIssues: new CrudApiClient(axios, '/tracked-issues'),
  usage: new UsageApiClient(axios),
  user: new UserApiClient(axios),
  users: new UsersApiClient(axios),
};

import React from 'react';

import {
  allCategories,
  allDocumentTypes,
  allRegions,
  allSectors,
  Category,
  Region,
  DocumentSearch,
  Sector,
  Topic,
} from 'tcf-shared/models';

import { useAppSelector } from '../../../utils/hooks';
import { MY_PORTFOLIO_TRACKED_ISSUES_STORE } from '../../../storeIds';

export interface DocumentSearchDescriptionProps {
  documentSearch: DocumentSearch;
  includeIssuesStoreId?: string;
  excludeIssuesStoreId?: string;
}

const DocumentSearchDescription = (props: DocumentSearchDescriptionProps) => {
  const { documentSearch, includeIssuesStoreId, excludeIssuesStoreId } = props;
  const includeIssuesList: Topic[] = useAppSelector(
    (state) => state?.serverStores[includeIssuesStoreId || '']?.payload?.results || [],
  );
  const trackedIssuesDict: { [key: string]: string } = useAppSelector(
    (state) => state?.serverStores[MY_PORTFOLIO_TRACKED_ISSUES_STORE]?.payload || {},
  );

  const excludeIssuesList: Topic[] = useAppSelector(
    (state) => state?.serverStores[excludeIssuesStoreId || '']?.payload?.results || [],
  );

  const searchParts = {
    documentTypes: documentSearch.documentTypes?.length && (
      <>
        <span className={'font-weight-bold'}>Type: </span>
        <span className={'font-italic'}>
          {documentSearch.documentTypes
            .map((id: string) => allDocumentTypes.find((t) => t.id === id)?.name || 'unknown type')
            .join(' or ')}
        </span>
      </>
    ),
    categories: documentSearch.categories?.length && (
      <>
        <span className={'font-weight-bold'}>Category: </span>
        <span className={'font-italic'}>
          {documentSearch.categories
            .map((id: string) => allCategories.find((c: Category) => c.id === id)?.name || 'unknown coverage')
            .join(' or ')}
        </span>
      </>
    ),
    regions: documentSearch.regions?.length && (
      <>
        <span className={'font-weight-bold'}>Region: </span>
        <span className={'font-italic'}>
          {documentSearch.regions
            .map((id: string) => allRegions.find((r: Region) => r.id === id)?.name || 'unknown region')
            .join(' or ')}
        </span>
      </>
    ),
    sectors: documentSearch.sectors?.length && (
      <>
        <span className={'font-weight-bold'}>Sector: </span>
        <span className={'font-italic'}>
          {documentSearch.sectors
            .map((id: string) => allSectors.find((s: Sector) => s.code === id)?.name || 'unknown sector')
            .join(' or ')}
        </span>
      </>
    ),
    searchText: documentSearch.searchText && (
      <>
        <span className={'font-weight-bold'}>Search terms: </span>
        <span className={'font-italic'}>'{documentSearch.searchText}'</span>
      </>
    ),
    includeIssues: documentSearch.includeIssues?.length && (
      <>
        <span className={'font-weight-bold'}>Included issues: </span>
        <span className={'font-italic'}>
          {documentSearch.includeIssues
            .map((id: string) => includeIssuesList.find((t: Topic) => t.id === id)?.name || trackedIssuesDict[id] || id)
            .join(' or ')}
        </span>
      </>
    ),
    excludeIssues: documentSearch.excludeIssues?.length && (
      <>
        <span className={'font-weight-bold'}>Excluded issues: </span>
        <span className={'font-italic'}>
          {documentSearch.excludeIssues
            .map((id: string) => excludeIssuesList.find((t: Topic) => t.id === id)?.name || id)
            .join(' or ')}
        </span>
      </>
    ),
  };

  let searchDescription: any = Object.keys(searchParts)
    .filter((key: string) => !!searchParts[key])
    .map(
      (key: string, index: number) =>
        searchParts[key] && (
          <span key={`sp${index}`}>
            {index > 0 && <span className={'font-weight-bold'}> and </span>}
            {searchParts[key]}
          </span>
        ),
    );
  if (!searchDescription?.length) {
    searchDescription = <span>Includes all subscribed content.</span>;
  }

  return searchDescription;
};

export default DocumentSearchDescription;

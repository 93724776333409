import React from 'react';

import { allCategories, allRegions, allSectors, Category, Region, SavedSearch, Sector } from 'tcf-shared/models';

export interface SavedSearchDescriptionProps {
  savedSearch: Partial<SavedSearch>;
  topicLookup?: { [id: string]: string };
}

const SavedSearchDescription = (props: SavedSearchDescriptionProps) => {
  const { savedSearch } = props;
  const topicLookup = props.topicLookup || {};

  const searchParts = {
    categories: savedSearch.categories?.length && (
      <>
        <span className={'font-weight-bold'}>Category: </span>
        <span className={'font-italic'}>
          {savedSearch.categories
            .map((id: string) => allCategories.find((c: Category) => c.id === id)?.name || 'unknown coverage')
            .join(' or ')}
        </span>
      </>
    ),
    regions: savedSearch.regions?.length && (
      <>
        <span className={'font-weight-bold'}>Region: </span>
        <span className={'font-italic'}>
          {savedSearch.regions
            .map((id: string) => allRegions.find((r: Region) => r.id === id)?.name || 'unknown region')
            .join(' or ')}
        </span>
      </>
    ),
    sectors: savedSearch.sectors?.length && (
      <>
        <span className={'font-weight-bold'}>Sector: </span>
        <span className={'font-italic'}>
          {savedSearch.sectors
            .map((id: string) => allSectors.find((s: Sector) => s.code === id)?.name || 'unknown sector')
            .join(' or ')}
        </span>
      </>
    ),
    includeIssues: savedSearch.includeIssues?.length && (
      <>
        <span className={'font-weight-bold'}>Issue: </span>
        <span className={'font-italic'}>
          {savedSearch.includeIssues
            .map((p: string) => topicLookup[p] || p)
            .filter((name?: string) => name)
            .join(' or ')}
        </span>
      </>
    ),
    excludeIssues: savedSearch.excludeIssues?.length && (
      <>
        <span className={'font-weight-bold'}>Not Issue: </span>
        <span className={'font-italic'}>
          {savedSearch.excludeIssues
            .map((p: string) => topicLookup[p] || p)
            .filter((name?: string) => name)
            .join(' or ')}
        </span>
      </>
    ),
    searchText: savedSearch.searchText?.length && (
      <>
        <span className={'font-weight-bold'}>Keywords: </span>
        <span className={'font-italic'}>{savedSearch.searchText}</span>
      </>
    ),
  };

  let searchDescription: any = Object.keys(searchParts)
    .filter((key: string) => !!searchParts[key])
    .map(
      (key: string, index: number) =>
        searchParts[key] && (
          <span key={`sp${index}`}>
            {index > 0 && <span className={'font-weight-bold'}> and </span>}
            {searchParts[key]}
          </span>
        ),
    );
  if (!searchDescription?.length) {
    searchDescription = <span>All subscribed articles</span>;
  }

  return searchDescription;
};

export default SavedSearchDescription;

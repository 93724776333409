import React, { useState } from 'react';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { allCategories, DocumentSearch, Query } from 'tcf-shared/models';

import { getAuthUser } from '../../reducers/authReducer';
import { NotFoundError } from '../../components';
import { readDocuments } from '../../actions/documentsActions';
import { useAppSelector, useLocationHistoryQuery } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import DocumentPreviewList from '../Document/components/DocumentPreviewList';
import DocumentsFilter from '../Document/components/DocumentsFilter';
import SearchSideBar from '../../components/SearchSideBar/SearchSideBar';

const ARTICLES_STORE = 'homeArticlesStore';
const ISSUES_MATCHES_STORE = 'homeDocsFilterIssueMatchesStore';
const INCLUDE_ISSUES_STORE = 'homeDocsFilterIssueListStore';
const EXCLUDE_ISSUES_STORE = 'homeDocsFilterExcludedIssueListStore';
const SAVED_SEARCHES_STORE = 'homeSavedSearchesStore';

const Home = () => {
  const { id: categoryIdParam } = useParams<{ id: string }>();

  const [query, replaceQuery, resetQuery, payload, isFetching, error] = useLocationHistoryQuery<Query>(
    'homeArticlesQuery',
    ARTICLES_STORE,
    (locationQuery?: Partial<Query>) => ({
      skip: 0,
      limit: 10,
      search: { categories: categoryIdParam ? [categoryIdParam] : [] },
      ...locationQuery,
    }),
    readDocuments,
  );

  const authUser = useAppSelector((s) => getAuthUser(s));

  const category = (categoryIdParam && allCategories.find((c) => c.id === categoryIdParam)) || undefined;
  const hideCategoriesFilter = !!categoryIdParam;
  const hideRegionsFilter = categoryIdParam === 'investigations';
  const title = category?.name ? 'Category' : 'Home';

  const [showFilterOnSm, setShowFilterOnSm] = useState(false);
  const toggleShowFilter = () => {
    setShowFilterOnSm((prevState) => !prevState);
    window.scrollTo(0, 0);
  };

  const [sortDropDownOpen, setSortDropDownOpen] = useState(false);
  const toggleSortDropDown = () => setSortDropDownOpen((prevState) => !prevState);

  if (!authUser || (categoryIdParam && authUser.categoryIds?.length && !authUser.categoryIds.includes(categoryIdParam))) {
    return <NotFoundError />;
  }

  const onSortBy = (event: any, sort: string) => {
    event.stopPropagation();
    const _search: DocumentSearch = { ...query?.search };
    if (sort === 'oldest') {
      _search.sortBy = 'publicationDate';
      _search.sortOrder = 'asc';
    } else if (sort === 'relevance') {
      _search.sortBy = 'relevance';
      _search.sortOrder = 'desc';
    } else {
      // newest
      _search.sortBy = 'publicationDate';
      _search.sortOrder = 'desc';
    }
    replaceQuery({ ...query, skip: 0, search: _search });
  };

  return (
    <AsyncPage title={title} loading={isFetching && !payload} error={error}>
      <Row>
        <Col md={3} className={`sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3  ${showFilterOnSm ? '' : 'd-none d-md-block'}`}>
          <DocumentsFilter
            authUser={authUser}
            query={query}
            hideCategoriesFilter={hideCategoriesFilter}
            hideRegionsFilter={hideRegionsFilter}
            onToggleShowFilter={toggleShowFilter}
            showFilterOnSm={showFilterOnSm}
            onQueryChange={replaceQuery}
            onResetQuery={resetQuery}
            issuesMatchesStoreId={ISSUES_MATCHES_STORE}
            includeIssuesStoreId={INCLUDE_ISSUES_STORE}
            excludeIssuesStoreId={EXCLUDE_ISSUES_STORE}
            savedSearchesStoreId={SAVED_SEARCHES_STORE}
          />
        </Col>
        <Col md={6} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className="float-right">
            {!showFilterOnSm && (
              <Button className={'d-inline d-md-none mr-2'} onClick={toggleShowFilter}>
                Search
              </Button>
            )}
            <Dropdown className={'d-inline'} isOpen={sortDropDownOpen} toggle={toggleSortDropDown}>
              <DropdownToggle caret>Sort</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  active={
                    (query?.search?.sortBy || 'publicationDate') === 'publicationDate' &&
                    (query?.search?.sortOrder || 'desc') === 'desc'
                  }
                  onClick={(e) => onSortBy(e, 'newest')}
                >
                  Newest
                </DropdownItem>
                <DropdownItem
                  active={
                    (query?.search?.sortBy || 'publicationDate') === 'publicationDate' && query?.search?.sortOrder === 'asc'
                  }
                  onClick={(e) => onSortBy(e, 'oldest')}
                >
                  Oldest
                </DropdownItem>
                <DropdownItem active={query?.search?.sortBy === 'relevance'} onClick={(e) => onSortBy(e, 'relevance')}>
                  Relevance
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <h4>Documents</h4>
          <div className={'clearfix'} />
          <DocumentPreviewList
            query={query}
            storeId={ARTICLES_STORE}
            onQueryChange={replaceQuery}
            includeIssuesStoreId={INCLUDE_ISSUES_STORE}
            excludeIssuesStoreId={EXCLUDE_ISSUES_STORE}
          />
        </Col>
        <Col md={3} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <SearchSideBar authUser={authUser} category={category} savedSearchesStoreId={SAVED_SEARCHES_STORE} />
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default Home;

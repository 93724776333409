import { combineReducers } from 'redux';
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr';

import { AuthState, authReducer } from './authReducer';
import { DocumentState, documentsReducer } from './documentsReducer';
import { OrganizationSelfManagementState, organizationSelfManagementReducer } from './organizationSelfManagementReducer';
import { OrganizationsState, organizationsReducer } from './organizationsReducer';
import { QueryState, queriesReducer } from './queriesReducer';
import { ReferenceDataState, referenceDataReducer } from './referenceDataReducer';
import { SearchState, searchReducer } from './searchReducer';
import { ServerStoresState, serverStoresReducer } from './serverStoresReducer';
import { UsersState, usersReducer } from './usersReducer';
import { trackedIssuesReducer } from './trackedIssuesReducer';

export interface AppState {
  auth: AuthState;
  document: DocumentState;
  organizations: OrganizationsState;
  referenceData: ReferenceDataState;
  search: SearchState;
  toastr: ToastrState;
  users: UsersState;
  queries: QueryState;
  serverStores: ServerStoresState;
  organizationSelfManagement: OrganizationSelfManagementState;
  trackedIssues: string[];
}

const rootReducer = combineReducers<AppState>({
  auth: authReducer,
  document: documentsReducer,
  organizations: organizationsReducer,
  referenceData: referenceDataReducer,
  search: searchReducer,
  toastr: toastrReducer,
  users: usersReducer,
  queries: queriesReducer,
  serverStores: serverStoresReducer,
  organizationSelfManagement: organizationSelfManagementReducer,
  trackedIssues: trackedIssuesReducer,
});

export default rootReducer;

import * as React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';

import { AuthUser } from 'tcf-shared/models';

import { NotFoundError } from '../../components';
import { paths } from '../../paths';
import EditSearch from '../../pages/SavedSearch/EditSearch';
import EntityList from '../../pages/Directory/EntityList';
import EntityView from '../../pages/Directory/EntityView';
import Home from '../../pages/Home/Home';
import ListCompanies from '../../pages/Company/ListCompanies';
import ListIssues from '../../pages/Issue/ListIssues';
import Login from '../../pages/Login/Login';
import LoginWithToken from '../../pages/Login/LoginWithToken';
import Logout from '../../pages/Logout/Logout';
import MyPortfolio from '../../pages/Home/MyPortfolio';
import Notifications from '../Header/Notifications';
import OrganizationSelfManagement from '../../pages/OrganizationSelfManagement';
import SavedSearchResults from '../../pages/SavedSearch/SavedSearchResults';
import ViewAccount from '../../pages/Account/ViewAccount';
import ViewCalendar from '../../pages/Calendar/View';
import ViewCompany from '../../pages/Company/ViewCompany';
import ViewDocument from '../../pages/Document/ViewDocument';
import ViewIssue from '../../pages/Issue/ViewIssue';
import ViewRegion from '../../pages/Region/ViewRegion';
import ViewSector from '../../pages/Sector/ViewSector';
import ViewSubscription from '../../pages/Account/ViewSubscription';

interface RoutesProps {
  authUser?: AuthUser;
  authError?: string;
  headerHeight: number;
}

const AdminRoutes = Loadable({
  loader: () => import('./AdminRoutes'),
  loading: () => <div />,
});

export default (props: RoutesProps) => {
  const { pathname, search } = useLocation();
  const { authUser, authError } = props;
  const isSubscriber = authUser?.isPremiumSubscriber;
  const isStaff = authUser?.isStaff;
  const redirectToLogin = <Redirect to={{ pathname: paths.LOGIN, state: { redirectTo: pathname + (search || '') } }} />;

  return (
    <>
      <Switch>
        <Route exact path={paths.LOGIN}>
          <Login />
        </Route>
        <Route exact path={paths.LOGIN_WITH_TOKEN}>
          <LoginWithToken />
        </Route>
        <Route path={paths.LOGOUT}>
          <Logout />
        </Route>
        {/* Redirect on an authentication error, but only if none of the above routes matched. */}
        {authError && <Route>{redirectToLogin}</Route>}
        {/* If the user is allowed to manage their own organization, allow for that route. */}
        {authUser?.canManageOrganization && (
          <Route exact path={paths.SELF_MANAGE_ORGANIZATION}>
            <OrganizationSelfManagement />
          </Route>
        )}
        {/* If the user is allowed to manage their own organization and has no subscription, force them to the self admin page. */}
        {authUser?.canManageOrganization && !authUser?.subscription && (
          <Route>
            <Redirect to={paths.SELF_MANAGE_ORGANIZATION} />
          </Route>
        )}
        {/* All routes below require a logged in subscriber authUser. Redirect if not subscriber,
            but only if none of the above routes matched. */}
        {!isSubscriber && <Route>{redirectToLogin}</Route>}
        <Route exact path={paths.HOME}>
          <Home />
        </Route>
        <Route exact path={paths.VIEW_CATEGORY}>
          <Home />
        </Route>
        <Route exact path={paths.VIEW_MY_PORTFOLIO}>
          <MyPortfolio />
        </Route>
        <Route exact path={paths.VIEW_DOCUMENT}>
          <ViewDocument {...props} />
        </Route>
        <Route exact path={paths.SAVED_SEARCH_RESULTS}>
          <SavedSearchResults />
        </Route>
        <Route exact path={paths.LIST_COMPANIES}>
          <ListCompanies />
        </Route>
        <Route exact path={paths.VIEW_COMPANY}>
          <ViewCompany />
        </Route>
        <Route exact path={paths.VIEW_SECTOR}>
          <ViewSector />
        </Route>
        <Route exact path={paths.LIST_ISSUES}>
          <ListIssues />
        </Route>
        <Route exact path={paths.VIEW_ISSUE}>
          <ViewIssue />
        </Route>
        <Route exact path={paths.VIEW_REGION}>
          <ViewRegion />
        </Route>
        <Route exact path={paths.VIEW_ACCOUNT}>
          <ViewAccount />
        </Route>
        <Route exact path={paths.VIEW_SUBSCRIPTION}>
          <ViewSubscription />
        </Route>
        <Route exact path={paths.EDIT_SEARCH}>
          <EditSearch />
        </Route>
        <Route exact path={paths.VIEW_CALENDAR}>
          <ViewCalendar />
        </Route>
        <Route exact path={paths.NOTIFICATIONS}>
          <Notifications />
        </Route>
        {authUser?.canViewDecisionMakers && (
          <Route exact path={paths.LIST_DECISION_MAKERS}>
            <EntityList decisionMakersOnly={true} />
          </Route>
        )}
        {authUser?.canManageDirectory && (
          <Route exact path={paths.LIST_DIRECTORY_ENTITY}>
            <EntityList decisionMakersOnly={false} />
          </Route>
        )}
        {authUser?.canViewDecisionMakers && (
          <Route exact path={paths.VIEW_DIRECTORY_ENTITY}>
            <EntityView />
          </Route>
        )}
        <Redirect exact from={paths.VIEW_INDUSTRY} to={paths.VIEW_SECTOR} />
        <Redirect exact from={paths.TOPICS_LIST} to={paths.LIST_ISSUES} />
        <Redirect exact from={paths.VIEW_TOPIC} to={paths.VIEW_ISSUE} />
        <Route path={paths.admin.ROOT} render={() => (isStaff ? null : <NotFoundError />)} />
        <Route>
          <NotFoundError />
        </Route>
      </Switch>

      {isStaff && <AdminRoutes />}
    </>
  );
};

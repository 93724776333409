import { Action } from '../actions/Action';

export const ADD_TRACKED_ISSUE = 'ADD_TRACKED_ISSUE';
export const REMOVE_TRACKED_ISSUE = 'REMOVE_TRACKED_ISSUE';

const initialState: string[] = [];

export const addTrackedIssue = (id: string) => ({
  type: ADD_TRACKED_ISSUE,
  payload: id,
});

export const removeTrackedIssue = (id: string) => ({
  type: REMOVE_TRACKED_ISSUE,
  payload: id,
});

export const trackedIssuesReducer = (state = initialState, action: Action): string[] => {
  switch (action.type) {
    case ADD_TRACKED_ISSUE:
      return [...state, action.payload];
    case REMOVE_TRACKED_ISSUE:
      return state.filter((id) => id !== action.payload);
    default:
      return state;
  }
};

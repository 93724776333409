import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

import { Query } from 'tcf-shared/models';

import { getAuthUser } from '../../reducers/authReducer';
import { searchTopics } from '../../actions/topicsActions';
import { useAppSelector, useLocationHistoryQuery } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import IssueFilter from './components/IssueFilter';
import IssueList from './components/IssueList';

const ISSUES_STORE = 'listIssuesStore';
const DEFAULT_QUERY = { skip: 0, limit: 100, search: { current: true, sortBy: 'name', sortOrder: 'asc' } };

const ListIssues = () => {
  const [query, replaceQuery, resetQuery, payload, isFetching, error] = useLocationHistoryQuery<Query>(
    'listIssuesQuery',
    ISSUES_STORE,
    (locationQuery?: Partial<Query>) => ({ ...DEFAULT_QUERY, ...locationQuery }),
    searchTopics,
  );

  const authUser = useAppSelector((s) => getAuthUser(s));
  const [showFilterOnSm, setShowFilterOnSm] = useState(false);
  const toggleShowFilter = () => setShowFilterOnSm((prevState) => !prevState);

  if (!authUser) return null;

  return (
    <AsyncPage title={'Issues'} loading={isFetching && !payload} error={error}>
      <Row>
        <Col md={3} className={`sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3  ${showFilterOnSm ? '' : 'd-none d-md-block'}`}>
          <div className="d-flex mb-2">
            <h4 className="mr-auto">Search</h4>
            {showFilterOnSm && (
              <Button className={'d-md-none mx-2'} onClick={toggleShowFilter}>
                Hide Search
              </Button>
            )}
            <Button className="mr-0" onClick={resetQuery}>
              Reset
            </Button>
          </div>
          <IssueFilter authUser={authUser} query={query} onQueryChange={replaceQuery} />
        </Col>
        <Col md={9} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className="d-flex mb-3">
            <h4 className="mr-auto">Issues</h4>
            {!showFilterOnSm && (
              <Button className={'d-md-none ml-2'} onClick={toggleShowFilter}>
                Search
              </Button>
            )}
          </div>
          <IssueList listIssueStoreId={ISSUES_STORE} query={query} onQueryChange={replaceQuery} />
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ListIssues;

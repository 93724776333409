import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Query, Region } from 'tcf-shared/models';

import { getRegion } from '../../actions/regionsActions';
import { readDocuments } from '../../actions/documentsActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import { useAppDispatch, useAppSelector, useLocationHistoryQuery } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import DocumentPreviewList from '../Document/components/DocumentPreviewList';
import IssueList from '../../components/SearchSideBar/IssueList';
import PopularDocumentList from '../../components/SearchSideBar/DocumentList';
import ViewCalendarButton from '../../components/SearchSideBar/ViewCalendarButton';
interface RegionRelated {
  region: Region;
  currentTopics: { id: string; name: string }[];
  topDocs: { id: string; title: string }[];
}

const ARTICLES_STORE = 'viewRegionArticlesStore';
const REGION_RELATED_STORE = 'viewRegionRelatedStore';

const ViewRegion = () => {
  const dispatch = useAppDispatch();

  const { id: urlId } = useParams<{ id: string }>();

  const [articleQuery, replaceArticleQuery, , articlePayload, isArticleFetching, articleError] = useLocationHistoryQuery<Query>(
    'viewRegionArticlesQuery',
    ARTICLES_STORE,
    (locationQuery?: Partial<Query>) => ({
      skip: 0,
      limit: 10,
      search: { regions: [urlId], forManagedList: false },
      ...locationQuery,
    }),
    readDocuments,
  );

  const regionRelatedStore = useAppSelector((s) => s.serverStores?.[REGION_RELATED_STORE]);
  const regionRelated: RegionRelated = regionRelatedStore?.payload?.results;

  const error = articleError || regionRelatedStore?.error;
  const isFetching = (isArticleFetching && !articlePayload) || regionRelatedStore?.isFetching;

  const regionName = regionRelated?.region?.name || 'Region';

  useEffect(() => {
    dispatch(getRegion(REGION_RELATED_STORE, urlId));
    return () => {
      dispatch(resetServerStore(REGION_RELATED_STORE));
    };
  }, [urlId, dispatch]);

  const topicsToShow: { id: string; name: string }[] = useMemo(
    () => regionRelated?.currentTopics?.sort((a, b) => a.name.localeCompare(b.name))?.slice(0, 10) || [],
    [regionRelated],
  );

  const docsToShow: { id: string; title: string }[] = useMemo(
    () => regionRelated?.topDocs?.slice(0, 10) || [],
    [regionRelated],
  );

  return (
    <AsyncPage title={regionName} loading={isFetching} error={error}>
      <Row>
        <Col sm={12} md={8} lg={9} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <DocumentPreviewList query={articleQuery} storeId={ARTICLES_STORE} onQueryChange={replaceArticleQuery} />
        </Col>
        <Col md={4} lg={3} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          <ViewCalendarButton />
          {topicsToShow.length > 0 ? <IssueList topics={topicsToShow} title={`Current Issues in ${regionName}`} /> : null}
          {docsToShow?.length ? (
            <PopularDocumentList documents={docsToShow} title={`Popular from our ${regionName} Bureau`} />
          ) : null}
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ViewRegion;

import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Card, CardHeader, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { DocumentType, Query, SavedSearch } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { NotFoundError } from '../../components';
import { getAuthUser } from '../../reducers/authReducer';
import { paths } from '../../paths';
import { setQuery } from '../../actions/queryActions';
import { searchSavedSearches } from '../../actions/savedSearchesActions';
import AsyncPage from '../AsyncPage/AsyncPage';
import DocumentPreviewList from '../Document/components/DocumentPreviewList';
import DocumentList from '../Document/components/DocumentList';
import { readDocuments } from '../../actions/documentsActions';
import SavedSearchDescription from './components/SavedSearchDescription';
import SavedSearchNameList from './components/SavedSearchNameList';

const SAVED_SEARCHES_STORE = 'savedSearchesStore';
const ARTICLES_STORE = 'savedSearchArticlesStore';
const SOURCES_STORE = 'savedSearchSourcesStore';
const ARTICLES_QUERY = 'savedSearchArticlesQuery';
const SOURCES_QUERY = 'savedSearchSourcesQuery';

const ARTICLES_TAB = 'Articles';
const SOURCES_TAB = 'Sources';

const SavedSearchResults = () => {
  const dispatch = useAppDispatch();
  const { id: savedSearchId } = useParams<{ id: string }>();
  const [delayRender, setDelayRender] = useState(true);
  const { pathname, state }: any = useLocation();
  const history = useHistory();

  const articlesQueryState = state?.articlesQuery || {};
  const articlesQueryString = JSON.stringify(articlesQueryState);
  const sourcesQueryState = state?.sourcesQuery || {};
  const sourcesQueryString = JSON.stringify(sourcesQueryState);
  const activeTab = state?.activeTab || ARTICLES_TAB;

  const articlesQuery = useAppSelector((s) => s.queries?.[ARTICLES_QUERY]);
  const sourcesQuery = useAppSelector((s) => s.queries?.[SOURCES_QUERY]);

  const articles = useAppSelector((s) => s.serverStores?.[ARTICLES_STORE])?.payload;
  const sources = useAppSelector((s) => s.serverStores?.[SOURCES_STORE])?.payload;
  const savedSearchStore = useAppSelector((s) => s.serverStores?.[SAVED_SEARCHES_STORE]);
  const authUser = useAppSelector((s) => getAuthUser(s));
  const userId = authUser?.id;

  const error = savedSearchStore?.error;
  const isFetching = savedSearchStore?.isFetching;
  const savedSearches = savedSearchStore?.payload?.results || [];
  const topicLookup = savedSearchStore?.payload?.topicLookup || {};
  const savedSearch = savedSearches.find((s: SavedSearch) => s.id === savedSearchId) || {};
  const savedSearchString = JSON.stringify(savedSearch);

  const setupArticlesQuery = (q: Query) => {
    q.skip = q.skip || 0;
    q.limit = q.limit || 10;
    q.search = { ...q.search, documentTypes: [DocumentType.article, DocumentType.transcript], forManagedList: false };
  };

  const setupSourcesQuery = (q: Query) => {
    q.skip = q.skip || 0;
    q.limit = q.limit || 10;
    q.search = { ...q.search, documentTypes: [DocumentType.source], forManagedList: false };
  };

  useEffect(() => {
    // Retrieve the saved searches for userId
    window.scrollTo(0, 0);
    if (userId) dispatch(searchSavedSearches(SAVED_SEARCHES_STORE, { search: { userId } }));
    setDelayRender(false);
  }, [userId, dispatch]);

  useEffect(() => {
    if (savedSearchString.length > 2) {
      const _search = JSON.parse(savedSearchString);
      const _articlesQuery = { search: { ..._search } };
      setupArticlesQuery(_articlesQuery);
      dispatch(readDocuments(ARTICLES_STORE, _articlesQuery));
      dispatch(setQuery(ARTICLES_QUERY, _articlesQuery));

      const _sourcesQuery = { search: { ..._search } };
      setupSourcesQuery(_sourcesQuery);
      dispatch(readDocuments(SOURCES_STORE, _sourcesQuery));
      dispatch(setQuery(SOURCES_QUERY, _sourcesQuery));
    }
  }, [savedSearchString, dispatch]);

  useEffect(() => {
    if (articlesQueryString.length > 2) {
      const q = JSON.parse(articlesQueryString);
      setupArticlesQuery(q);
      dispatch(readDocuments(ARTICLES_STORE, q));
      dispatch(setQuery(ARTICLES_QUERY, q));
    }
  }, [articlesQueryString, dispatch]);

  useEffect(() => {
    if (sourcesQueryString.length > 2) {
      const q = JSON.parse(sourcesQueryString);
      setupSourcesQuery(q);
      dispatch(readDocuments(SOURCES_STORE, q));
      dispatch(setQuery(SOURCES_QUERY, q));
    }
  }, [sourcesQueryString, dispatch]);

  if (!authUser) {
    return <NotFoundError />;
  }

  const onToggleTab = (tab: string) => {
    if (activeTab !== tab) {
      history.push(pathname, { articlesQuery, sourcesQuery, activeTab: tab });
    }
  };

  const onSourcesPageChange = (q: Query) => {
    setupSourcesQuery(q);
    history.push(pathname, { articlesQuery, sourcesQuery: q, activeTab });
  };

  const onQueryChange = (q: Query) => {
    setupArticlesQuery(q);
    history.push(pathname, { articlesQuery: q, sourcesQuery, activeTab });
  };

  const articleCount = articles?.total;
  const sourceCount = sources?.total;

  const documentPreviewList = (
    <DocumentPreviewList
      queryId={ARTICLES_QUERY}
      storeId={ARTICLES_STORE}
      onQueryChange={onQueryChange}
      hideSearchSummary
      hideResultSummary
    />
  );

  return (
    <AsyncPage title={'Saved search results'} error={error} loading={delayRender || isFetching}>
      <Row>
        <Col md={9} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <h4>Results from saved search</h4>
          <p className={'mb-3'}>
            {activeTab === ARTICLES_TAB &&
              articleCount != null &&
              `Found ${articleCount.toLocaleString()} article${articleCount === 1 ? '' : 's'} matching search criteria`}
            {activeTab === SOURCES_TAB &&
              sourceCount != null &&
              `Found ${sourceCount.toLocaleString()} source document${sourceCount === 1 ? '' : 's'} matching search criteria`}
          </p>
          {(sourceCount ?? 0) > 0 ? (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    className={activeTab === ARTICLES_TAB ? 'active' : ''}
                    onClick={() => onToggleTab(ARTICLES_TAB)}
                    title={'View articles matching saved search'}
                  >
                    Articles
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={activeTab === SOURCES_TAB ? 'active' : ''}
                    onClick={() => onToggleTab(SOURCES_TAB)}
                    title={'View source documents matching saved search'}
                  >
                    Source Documents
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={ARTICLES_TAB}>
                  {activeTab === ARTICLES_TAB && <div className={'mt-2'}>{documentPreviewList}</div>}
                </TabPane>
                <TabPane tabId={SOURCES_TAB}>
                  {activeTab === SOURCES_TAB && (
                    <div className={'mt-2'}>
                      <DocumentList queryId={SOURCES_QUERY} storeId={SOURCES_STORE} onQueryChange={onSourcesPageChange} />
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </>
          ) : (
            documentPreviewList
          )}
        </Col>
        <Col md={3} className={'sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3'}>
          {savedSearch && (
            <>
              <Card className="mb-3">
                <CardHeader>Saved Search Details</CardHeader>
                <CardBody>
                  <Table responsive borderless size="sm">
                    <tbody>
                      <tr>
                        <td className="text-right">Name:</td>
                        <td>{savedSearch.name}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Alerts:</td>
                        <td>{savedSearch.alert ? 'On' : 'Off'}</td>
                      </tr>
                      <tr>
                        <td className="text-right">Criteria:</td>
                        <td>
                          <SavedSearchDescription savedSearch={savedSearch} topicLookup={topicLookup} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <h6 className={'ml-0'}>
                    <i>
                      <Link
                        to={paths.EDIT_SEARCH.replace(':id', savedSearch.id).replace(':userId', userId!)}
                        title="Edit saved search"
                      >
                        Edit saved search...
                      </Link>
                    </i>
                  </h6>
                </CardBody>
              </Card>
              <SavedSearchNameList savedSearches={savedSearches} />
            </>
          )}
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default SavedSearchResults;

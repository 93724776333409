// Use this if you want your query objects to interact correctly with the back/forward browser button.

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { resetServerStore } from '../../actions/serverStoreActions';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export const useLocationHistoryQuery = <T = unknown, P = unknown>(
  queryKey: string, // Object key in location history state.  Must be unique per page.
  storeId: string,
  resolveQuery: (locationQuery?: Partial<T>) => Partial<T>,
  runQueryAction?: (storeId: string, query: T) => void, // Must be stable.
) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const locationState: { [key: string]: Partial<T> | undefined } = location.state || {};
  const locationStateString = JSON.stringify(locationState);
  const locationStateQuery = locationState[queryKey];
  const resolvedQuery = resolveQuery(locationStateQuery);
  const resolvedQueryString = JSON.stringify(resolvedQuery);

  const [query, setQuery] = useState<Partial<T>>(resolvedQuery);

  const serverStore = useAppSelector<{ error?: string; isFetching?: boolean; payload?: unknown }>(
    (s) => s.serverStores?.[storeId] ?? {},
  );

  useEffect(() => {
    // This ensures the local and location states are in sync when the back or forward button is clicked.
    setQuery(JSON.parse(resolvedQueryString));
  }, [resolvedQueryString, setQuery]);

  const updateHistory = useCallback(
    debounce((newState) => {
      history.push({ ...history.location, state: newState });
    }, 700),
    [history],
  );

  useEffect(() => {
    if (runQueryAction) {
      dispatch(runQueryAction(storeId, JSON.parse(resolvedQueryString)));
    }
  }, [dispatch, runQueryAction, storeId, resolvedQueryString]);

  useEffect(() => {
    return () => {
      dispatch(resetServerStore(storeId));
    };
  }, [dispatch, storeId]);

  const replaceQuery = useCallback(
    (newQuery: T, _debounce?: boolean) => {
      const _newQuery = { ...newQuery };
      const _locationState = JSON.parse(locationStateString);
      setQuery(_newQuery);
      updateHistory({ ..._locationState, [queryKey]: _newQuery });
      // We do it this way because there may already be something in the debounce queue (for
      // example, enter keywords then immediately change the page).  It's less efficient, but I
      // haven't noticed the difference in my testing.
      if (!_debounce) updateHistory.flush();
    },
    [queryKey, locationStateString, updateHistory],
  );

  const resetQuery = () => {
    const { [queryKey]: ignore, ...newLocationState } = locationState;
    updateHistory(newLocationState);
    updateHistory.flush();
  };

  return [query, replaceQuery, resetQuery, serverStore.payload as P, serverStore.isFetching, serverStore.error] as const;
};

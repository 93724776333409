import React from 'react';
import { Link } from 'react-router-dom';

import { allRegions, allSectors, AuthUser, Document } from 'tcf-shared/models';

import { DocumentRelated } from '../../../reducers/documentsReducer';
import { paths } from '../../../paths';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment/moment';
import IssueListDataController from '../../../components/SearchSideBar/IssueList';

const getItemList = (ids: any[], refData: any[]) => {
  const usedIds: any = {};
  return (
    ids
      // Map to ref values.
      .map((i) => refData.find((rd) => (rd.code || rd.id) === (i.code || i.id)))
      // Normalize structure to { id, name }, use industry sector.
      .map((i) => (i && i.sector ? { id: i.sector.code, name: i.sector.name } : i))
      // Remove duplicate values.
      .filter((item) => {
        if (item && usedIds[item.id] !== 1) {
          usedIds[item.id] = 1;
          return true;
        }
        return false;
      })
      .sort((a, b) => a.name.localeCompare(b.name))
  );
};

const getLinks = (slug: string, ids: any[], refData: any[]) => {
  return getItemList(ids, refData).map((i) =>
    i ? (
      <li key={i.id || i.code}>
        <Link to={`/${slug}/${i.id || i.code}`}>{i.name}</Link>
      </li>
    ) : null,
  );
};

const getRelatedLinks = (slug: string, itemList: any[]) => {
  const usedIds: any = [];
  return itemList
    .filter((item) => {
      if (item && usedIds[item.id] !== 1) {
        usedIds[item.id] = 1;
        return true;
      }
      return false;
    })
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) =>
      item ? (
        <li key={item.id}>
          <Link to={`/${slug}/${item.id}`}>{item.name}</Link>
        </li>
      ) : null,
    );
};

export interface OwnProps {
  authUser?: AuthUser;
  document: Document;
  documentRelated?: DocumentRelated;
}

type DocumentTagsProps = OwnProps;

const DocumentTags = (props: DocumentTagsProps) => {
  const { document, documentRelated, authUser } = props;
  const userRegionIds = authUser?.regionIds || null;
  const userSectorCodes = authUser?.sectorCodes || null;

  if (!document) {
    return null;
  }

  return (
    <div className="mb-2">
      <Card className={'mb-3'}>
        <CardHeader>Document</CardHeader>
        <CardBody>
          <h6>{document.title}</h6>
          <div className="mt-2">
            {document.meta?.publishing?.published ? (
              `Published ${moment(document.meta.publishing!.displayPublishedAt).format('MMM D, YYYY')}`
            ) : (
              <h4>Not published</h4>
            )}
          </div>
        </CardBody>
      </Card>
      {/*{document.meta?.publishing?.published ? (*/}
      {/*  <Card className={'mb-3'}>*/}
      {/*    <CardHeader>{`Published ${moment(document.meta.publishing!.displayPublishedAt).format('MMM D, YYYY')}`}</CardHeader>*/}
      {/*  </Card>*/}
      {/*) : (*/}
      {/*  <h4>Not published</h4>*/}
      {/*)}*/}
      {(documentRelated?.topics?.length ?? 0) > 0 && (
        <IssueListDataController
          title={'Issues'}
          topics={documentRelated?.topics || []}
          instructionalText="Bookmark Issues to add to My Portfolio"
        />
      )}
      {(documentRelated?.companies?.length ?? 0) > 0 && (
        <Card className={'mb-3'}>
          <CardHeader>Companies</CardHeader>
          <CardBody>
            <ul className={'pl-3 mb-0'}>{getRelatedLinks('companies', documentRelated!.companies!)}</ul>
          </CardBody>
        </Card>
      )}
      {(document?.sectors?.length ?? 0) > 0 && (
        <Card className={'mb-3'}>
          <CardHeader>Sectors</CardHeader>
          <CardBody>
            <ul className={'pl-3 mb-0'}>
              {userSectorCodes
                ? getItemList(document.sectors, allSectors).map((r) => (
                    <li key={r.id}>
                      {userSectorCodes.includes(r.id) ? (
                        <Link title="View articles from this industry sector" to={paths.VIEW_SECTOR.replace(':id', r.id)}>
                          {r.name}
                        </Link>
                      ) : (
                        <span
                          title="This industry sector is not included in your subscription"
                          style={{ cursor: 'not-allowed' }}
                        >
                          {r.name}
                        </span>
                      )}
                    </li>
                  ))
                : getLinks('sectors', document.sectors, allSectors)}
            </ul>
          </CardBody>
        </Card>
      )}
      {(document?.regions?.length ?? 0) > 0 && (
        <Card className={'mb-3'}>
          <CardHeader>Regions</CardHeader>
          <CardBody>
            <ul className={'pl-3 mb-0'}>
              {userRegionIds
                ? getItemList(document.regions, allRegions).map((r) => (
                    <li key={r.id}>
                      {userRegionIds.includes(r.id) ? (
                        <Link title="View articles from this bureau" to={paths.VIEW_REGION.replace(':id', r.id)}>
                          {r.name}
                        </Link>
                      ) : (
                        <span title="This region is not included in your subscription" style={{ cursor: 'not-allowed' }}>
                          {r.name}
                        </span>
                      )}
                    </li>
                  ))
                : getLinks('regions', document.regions, allRegions)}
            </ul>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default DocumentTags;
